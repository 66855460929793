<!-- System: STA
    Purpose: Instant capture shows modal
        of live image of member
-->
<template>
  <!-- Instant Capture Modal -->
  <v-dialog
    max-width="800"
    class="pa-0 white-bg"
    v-model="instantCaptureModal"
    @click:outside="modal_hide"
  >
    <!-- Instant Capture Card -->
    <v-card>
      <v-card-title class="headline white lighten-2 justify-center">
        Instant Capture
        <v-spacer></v-spacer>
        <!-- Images Of Member Real Time -->
        <a :href="instant_capture_src" class download title="Download Image">
          <v-btn text v-if="instant_capture_src">
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </a>

        <v-btn icon @click="modal_hide()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div v-if="instant_capture_src">
        <v-img :src="instant_capture_src" class="img-fluid w-100" width="800" />
      </div>
      <!-- Loader If Image Soucre Is Empty-->
      <Loader v-if="!instant_capture_src" :show="loader" page="realTime" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    Loader: () => import("@/components/includes/CreateVideoLoader"),
  },
  name: "InstantCaptrue",
  data() {
    return {
      instantCaptureModal: false,
      instant_capture_src: null,
      user_id: null,
    };
  },
  computed: {
    loader() {
      return this.instant_capture_src ? false : true;
    },
  },
  mounted() {
    /**
     * Custom event to update image source
     */
    this.$root.$on("instant_capture", (data, userId = null) => {
      if (this.user_id !== null && this.user_id === userId) {
        this.$store.commit("custom/toggle_loader", false);
        this.instant_capture_src = data;
        this.instantCaptureModal = true;
      } else {
        this.$socket.emit("stop_real_time_monitor", userId);
        this.$store.commit("custom/toggle_loader", false);
      }
    });
    this.$root.$on("send_member_id", (data) => {
      this.user_id = data;
    });
    this.$root.$on("open_modal", (data) => {
      this.instantCaptureModal = true;
    });
  },
  methods: {
    /**
     * This function is resposible for
     * hide modal and send emit to server
     * and clear previous data
     */
    modal_hide() {
      this.instant_capture_src = null;
      this.instantCaptureModal = false;
      if (this.user_id !== null) {
        this.$socket.emit("stop_real_time_monitor", this.user_id);
      }
      this.user_id = null;
    },
    /**
     * This function is resposible for
     * clear source
     */
    modal_show() {
      this.instant_capture_src = null;
    },
  },
};
</script>

<style scoped>
</style>
